import {Pipe, PipeTransform} from '@angular/core';
import {Facility} from '../../models/facility';
import {getId} from '../../utils/type.utils';

@Pipe({name: 'facility'})
export class FacilityPipe implements PipeTransform {
    transform(input: number | {[key: string]: any}, prop?: string): Facility | any {
        const f = Facility.get(getId(input));
        return f && prop ? f[prop] : f;
    }
}
